<template>
  <footer class="footer">
    <b-container>
      <b-row style="position: relative">
        <div class="footer-arrow" @click="scrollToTop"></div>
        <b-col cols="6" md="12" lg="7">
          <div class="footer-main-nav">
            <router-link to="/about" class="footer-main-nav__item">{{ $t('Footer_about_us_title') }}</router-link>
            <router-link to="/about/vacancies" class="footer-main-nav__item">{{ $t('Footer_careers_title') }}</router-link>
            <router-link to="/about/partners" class="footer-main-nav__item">{{ $t('Footer_partners_title') }}</router-link>
            <router-link to="/contacts" class="footer-main-nav__item">{{ $t('Footer_address_title') }}</router-link>
            <router-link to="/promo" class="footer-main-nav__item">{{ $t('Footer_promotion_title') }}</router-link>
          </div>
        </b-col>
        <b-col cols="6" md="12" lg="5">
          <div class="footer-nav">
            <router-link to="/blog" class="footer-nav__item">{{ $t('Footer_blog_title') }}</router-link>
            <router-link to="/help" class="footer-nav__item">{{ $t('Footer_help_page') }}</router-link>
            <router-link to="/policy" class="footer-nav__item">{{ $t('Footer_policy_title') }}</router-link>
          </div>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col cols="12" lg="9">
          <div class="footer-contact">
            <a href="tel:77471111818" class="footer-contact__phone">+7 (747) 111 18 18</a>
            <router-link to="/contacts" class="q-btn q-btn-white footer-contact__btn">
              {{ $t('Footer_write2us') }}
            </router-link>
            <router-link to="/whatsapp" class="q-btn q-btn-white footer-contact__btn footer-contact__btn-wp">
              {{
                $t('Footer_order2whatsapp')
              }}
            </router-link>
          </div>
          <div class="mt-2 mb-4">
           <router-link to="/legacy" class="text-white pointer">{{$t('Legacy_public_policy')}}</router-link>
            <br>
           <router-link to="/policy" class="text-white pointer">{{$t('Footer_policy_title')}}</router-link>
          </div>
        </b-col>
        <b-col cols="12" lg="3">
          <div class="footer-socials">
            <a target="_blank" href="https://www.instagram.com/qulpynai_bakery/" class="footer-socials__item social-ig"></a>
            <a target="_blank" href="https://www.youtube.com/@qulpynai_bakery" class="footer-socials__item social-yt"></a>
          </div>
        </b-col>
      </b-row>
      <b-row class="justify-content-between">
        <b-col cols="12" lg="6">
          <div class="footer-copyright">
            <div class="footer-copyright__text">
              {{ $t('Footer_copyright_legacy') }}
              <br>{{ $t('Footer_legacy') }}
            </div>
            <img src="@/assets/images/visa.png" alt="visa" class="footer-copyright__img">
            <img src="@/assets/images/mastercard.png" alt="mastercard" class="footer-copyright__img">
          </div>
        </b-col>
        <b-col cols="12" lg="6">
          <div class="footer-sign">
<!--            <a href="http://simplexteam.kz/" target="_blank">-->
<!--              <img src="@/assets/images/simplex.png" alt="simplex">-->
<!--            </a>-->
          </div>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
export default {
    name: "Footer",
    methods: {
        scrollToTop() {
            scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }
    },
}
</script>
