<template>
  <div class="mobile-nav">
    <div class="mobile-nav">
      <b-container class="mobile-nav__sticky">
        <div
          class="mobile-nav__menu"
          @click="menuActive = true"
        ></div>
        <router-link
          :class="{'mobile-nav__logo-sticky': sticky}"
          to="/?section=all"
          class="mobile-nav__logo"
        >
          <img src="@/assets/images/logo.svg" alt="logo">
        </router-link>
        <div class="main-cart">
          <router-link to="/cart" class="btn q-btn q-btn-red main-cart__btn">
            <span>{{ quantity }}</span>
          </router-link>
        </div>
      </b-container>
      <div v-if="$route.path === '/cart' && sticky" class="mobile-nav__menu-sections-wrap">
        <scrollactive
          :offset="80"
          active-class="sticky-nav__breadcrumb-active"
          class="mobile-nav__menu-sections cart"
        >
          <a
            v-for="(item, i) in cartSections"
            :key="`nav-cart-section__${i}`"
            :href="item.link"
            class="sticky-nav__breadcrumb scrollactive-item"
          >{{ $t(item.name) }}</a>
        </scrollactive>
      </div>
      <scrollactive
        v-else-if="$route.path === '/' && sticky"
        :offset="120"
        class="mobile-nav__menu-sections"
      >
        <a
            @click="sectionClear"
            :class="[!$route.query.section ? 'text-red' : '']"
            class="scrollactive-item sticky-nav__link "
        >
          {{ $t('Cart_Step3_btn2show') }}
        </a>
        <a
          v-if="sections"
          v-for="(section, i) in sections"
          :key="`mobile-sticky-nav-item__${i}`"
          :class="[section.slug === elements[$route.query.section] ? 'text-red' : '']"
          @click="sectionHandler(section)"
          class="scrollactive-item sticky-nav__link"
        >
        <span v-if="section"> {{ section.name }} </span>
        </a>

      </scrollactive>
      <scrollactive
        v-else-if="sticky"
        :offset="80"
        class="mobile-nav__menu-sections"
      >
        <a
            @click="sectionClear"
            :class="[!$route.query.section ? 'text-red' : '' || $route.query.section === 'all' ? 'text-red' : '']"
            class="scrollactive-item sticky-nav__link "
        >
          {{ $t('Cart_Step3_btn2show') }}
        </a>
        <span v-if="sections">
             <router-link
                 v-for="(section, i) in sections"
                 :key="`mobile-sticky-nav-item__${i}`"
                 :class="[section.slug === elements[$route.query.section] ? 'text-red' : '']"
                 @click="sectionHandler(section)"
                 class="scrollactive-item sticky-nav__link"
             >
          {{ section.name }}
        </router-link>
        </span>
      </scrollactive>
    </div>
    <div v-if="menuActive" class="inner-menu">
      <b-container>
        <div class="inner-menu__head">
          <div class="inner-menu__head-left">
            <div
              class="inner-menu__close"
              @click="hideMenu"
            ></div>
            <q-dropdown
              :options="langOptions"
              :value="currentLang"
            />
          </div>
          <div class="auth-form">
            <button
              class="q-btn q-btn-skyblue auth-form__btn"
              @click="authActive = true"
            >
              {{ (currentUser && currentUser.name) || $t('MainNavMobile_enter') }}
            </button>
          </div>
        </div>
        <div class="inner-menu__nav-items">
          <span
            v-for="(item, i) in menu"
            :key="`mobile-menu-item__${i}`"
            @click="routerGo(item.link)"
          >
            {{ $t(item.name) }}
          </span>

          <span @click="routerGo('/whatsapp')" class="q-btn q-btn-green">{{ $t('MainNavMobile_order2whatsapp') }}</span>
        </div>
        <br />
        <br />
        <div class="inner-menu__footer">
          <a class="inner-menu__call" href="tel:77471111818">+7 (747) 111 18 18</a>
<!--          <br />-->
<!--          <a href="https://api.whatsapp.com/send/?phone=77471111818&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%21+%D0%AF+%D1%85%D0%BE%D1%87%D1%83+%D0%B7%D0%B0%D0%BA%D0%B0%D0%B7%D0%B0%D1%82%D1%8C+%D1%82%D0%BE%D1%80%D1%82&type=phone_number&app_absent=0"-->
<!--             target="_blank" class="q-btn q-btn-green">-->
<!--            +7 (747) 111 18 18-->
<!--          </a>-->

          <q-dropdown
            :options="cityOptions"
            :value="cityOptions[0]"
          />
          <button
            class="q-btn q-btn-red inner-menu__button"
            @click="routerGo('/cart')"
          >
            {{$t('MainNavMobile_cart_name')}}
          </button>
        </div>
      </b-container>
    </div>
    <div
      class="menu-auth"
      v-if="menuActive && authActive"
    >
      <b-container>
        <div class="menu-auth__head">
          <div
            class="menu-auth__close"
            @click="hideAuth"
          ></div>
        </div>
        <div v-if="!userAuthorized" class="menu-auth__form">
          <div class="q-tab mb-3">
            <div class="q-tab__item" :class="{'q-tab__item-active': authState === 'login'}" @click="authState = 'login'">
              {{ $t('MainNavMobile_login') }}</div>
            <div class="q-tab__item" :class="{'q-tab__item-active': authState === 'signup'}" @click="authState = 'signup'">
              {{ $t('MainNavMobile_register') }}</div>
          </div>
          <login-form v-show="authState === 'login'" />
          <signup-form v-show="authState === 'signup'" />
          <div v-show="authState === 'signup'" class="auth-form__link" @click="authState = 'login'">{{ $t('MainNav_Auth_registered') }}</div>
        </div>
        <div v-else class="mobile-nav__auth">
          <div class="mobile-nav__auth-link" @click="routerGo('/cabinet')">{{ $t('MainNavMobile_cabinet') }}</div>
          <div class="mobile-nav__auth-link" @click="logout">{{ $t('MainNavMobile_exit') }}</div>
          <div class="mobile-nav__auth-back" @click="authActive = false">{{ $t('MainNavMobile_back') }}</div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import QDropdown from "@/components/QDropdown/QDropdown.vue";
import LoginForm from "@/components/Auth/LoginForm.vue";
import SignupForm from "@/components/Auth/SignupForm.vue";
import { mapGetters, mapState } from "vuex";

export default {
    name: "MainNavMobile",
    props: {
        sticky: Boolean,
    },
    components: {
        QDropdown,
        LoginForm,
        SignupForm,
    },

    data() {
        return {
            elements: {torty: 'torty', deserty: 'deserty', pirogi: 'pirogi', vypecka: 'vypecka'},
            cityOptions: [{name:'Алматы'}],
            langOptions: [
              {
                name: 'Рус',
                value: 'ru',
              },
              {
                name: 'Каз',
                value: 'kz'
              }
            ],
            menuActive: false,
            authActive: false,
            authState: 'login',
            menu: [
                {
                    name: 'MainNav_menu',
                    link: '/',
                },
                {
                    name: 'MainNav_blog',
                    link: '/blog',
                },
                {
                    name: 'MainNav_about_us',
                    link: '/about',
                },
                {
                    name: 'MainNav_contacts',
                    link: '/contacts',
                },
                {
                    name: 'MainNav_bonuses',
                    link: '/bonuses',
                },
            ],
            cartSections: [
                {
                    name: 'MainNavMobile_step1_contacts',
                    link: '#cart-step-1',
                },
                {
                    name: 'MainNavMobile_step2_sposob',
                    link: '#cart-step-2',
                },
                {
                    name: 'MainNavMobile_step3_cart',
                    link: '#cart-step-3',
                },
                {
                    name: 'MainNavMobile_step4_pay',
                    link: '#cart-step-4',
                },
            ],
        }
    },
    computed: {
        ...mapGetters({
            sections: 'products/product_sections',
        }),
        ...mapState({
            amount: state => state.cart.amount,
            products: state => state.cart.products,
            loading: state => state.cart.loading,
            quantity: state => state.cart.productsQuantity,
        }),
    },

    methods: {
        sectionHandler(section){
            this.$router.push({path: '/', query:{section: section.slug}})
        },
        sectionClear(){
          this.$router.replace({query:{...this.$route.query,section:'all'}});
        },
        hideMenu() {
            this.menuActive = false;
        },
        hideAuth() {
            this.menuActive = false;
            this.authActive = false;
        },
        routerGo(route) {
            const { path: current } = this.$route;
            if (route === current) return;
            this.$router.push(route);
            this.hideAuth();
        },
        async logout() {
            try {
                await this.$store.dispatch('auth/logout')
            } catch (e) {
                console.error(e);
            } finally {
                this.routerGo('/');
            }
        },
    },
}
</script>
<style scoped>
.text-red{
  color: #E10714!important;
}
</style>